<template>
  <div
    class="w-full py-8 mx-auto sm:px-6 lg:px-0 lg:grid lg:grid-cols-12 lg:gap-8"
  >
    <div class="col-span-1 lg:col-span-2">
      <nav aria-label="Sidebar" class="sticky top-24 divide-y divide-gray-300">
        <div class="pb-8 space-y-1">
          <button
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            type="button"
            @click="() => goTo(tab.key)"
            :class="
              isCurrentTab(tab.key)
                ? 'bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-semibold rounded-md w-full'
                : 'text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-semibold rounded-md w-full'
            "
          >
            <span class="truncate">
              {{ tab.label }}
            </span>
          </button>
          <component
            v-if="sidebar"
            :is="sidebar.component"
            v-bind="{ ...sidebar.props }"
          />
        </div>
      </nav>
    </div>
    <div class="col-span-1 md:col-span-10">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableLayoutRouter',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    route: {
      type: String,
      default: '',
    },
    sidebar: {
      type: Object,
      default: null,
    },
  },
  methods: {
    goTo(tab) {
      let nameArray = this.$route.name.split('/');
      nameArray[nameArray.length - 1] !== tab &&
        this.$router.push({ path: `/${this.route}/${tab}` });
    },
    isCurrentTab(tab) {
      let nameArray = this.$route.name.split('/');
      return nameArray[nameArray.length - 1] === tab;
    },
  },
};
</script>
